import { delay } from 'bluebird';

export async function retry<R>(
  fn: () => Promise<R> | R,
  option: { times: number; interval: number },
): Promise<R> {
  let error = null;

  for (let time = 0; time < option.times; time++) {
    try {
      let res: Promise<R> | R = fn();
      if (res instanceof Promise) {
        res = await res;
      }
      return res;
    } catch (_error) {
      error = _error;
      await delay(option.interval);
    }
  }

  throw error;
}
