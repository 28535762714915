import _ from 'lodash';
import { randomInt } from './rand';

export function weightSample<T>(
  candidates: { weight: number; item: T }[],
  count = 1,
  invert = false,
  takeOut = false,
): T[] {
  if (count > candidates.length && takeOut === true) {
    throw new Error('Insufficient number');
  }

  const elements: T[] = [];

  for (let n = 0; n < count; n++) {
    candidates = _.shuffle(candidates);
    // Number of decimal places
    let numberOfDecimalPlaces = 0;
    candidates.forEach((candidate) => {
      const weight = candidate.weight.toString();
      if (weight.includes('.')) {
        numberOfDecimalPlaces = Math.max(
          numberOfDecimalPlaces,
          weight.split('.')[1].length,
        );
      }
    });

    const base = Math.pow(10, numberOfDecimalPlaces);
    const total = candidates.reduce(
      (prev, candidate) => prev + candidate.weight * base,
      0,
    );

    let random = randomInt(total);
    let candidate = candidates[0];

    for (let i = 0; i < candidates.length; i++) {
      candidate = candidates[i];

      if (invert) {
        random -= total - candidate.weight * base;
      } else {
        random -= candidate.weight * base;
      }

      if (random < 0) {
        if (takeOut) {
          candidates.splice(i, 1);
        }
        break;
      }
    }

    elements.push(candidate.item);
  }

  return elements;
}
