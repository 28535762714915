import { CommonModule } from '@angular/common';
import { EnvironmentInjector, Injector, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  AsyncJobDefaultEmptyDirective,
  AsyncJobDefaultLoadingDirective,
  AsyncJobDirective,
} from './async-job.component';
import { injectorRef } from './injector-ref';
export * from './async-job';
export * from './async-job.component';
export * from './injector-ref';

@NgModule({
  imports: [CommonModule, MatProgressBarModule, MatIconModule],
  declarations: [
    AsyncJobDirective,
    AsyncJobDefaultEmptyDirective,
    AsyncJobDefaultLoadingDirective,
  ],
  exports: [
    AsyncJobDirective,
    AsyncJobDefaultEmptyDirective,
    AsyncJobDefaultLoadingDirective,
  ],
})
export class AsyncJobModule {
  public constructor(
    _injector: Injector,
    _environmentInjector: EnvironmentInjector,
  ) {
    injectorRef.injector = _injector;
    injectorRef.environmentInjector = _environmentInjector;
  }
}
